<template>
	<a-modal v-model="show" title="广告信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="所属位置" label-position="top" prop="positionId">
						<a-select v-model="formData.positionId">
							<a-select-option v-for="posi in positionList" :value="posi.id" :key="posi.id">{{ posi.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="广告名称" label-position="top" prop="title">
						<a-input v-model="formData.title" placeholder="请输入广告名称"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="跳转地址" label-position="top" prop="link">
						<a-input v-model="formData.link" placeholder="请输入跳转地址"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="状态" label-position="top" prop="status">
						<a-select v-model="formData.status">
							<a-select-option :value="1">可用</a-select-option>
							<a-select-option :value="0">不可用</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="排序" label-position="top">
						<a-input-number style="width:100%" :min="0" v-model="formData.sort" placeholder="请输入排序"></a-input-number>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col span="8">
					<up-img :thumb="formData.src" @success="e => (formData.src = e)" ref="upimg"></up-img>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';
export default {
	components: { upImg },
	data() {
		return {
			show: false,
			loading: false,
			upimg: this.$refs.upimg,
			positionList: [],
			formData: {
				positionId: '',
				src: '',
				title: '',
				link: '',
				sort: '',
				status: ''
			},
			formRule: {
				title: [{ required: true, message: '请输入广告名称' }],
				status: [{ required: true, message: '请选择广告状态' }],
				link: [{ required: true, message: '请输入跳转地址' }],
				positionId: [{ required: true, message: '请选择所属位置' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getposition();
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
				} else {
					this.formData.positionId = '';
					this.formData.src = '';
					this.formData.title = '';
					this.formData.link = '';
					this.formData.sort = '';
					this.formData.status = '';
				}
			});
		},
		getposition() {
			this.http.get('/platform/advertising/position', null).then(ret => {
				this.positionList = ret.data;
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					this.formData.thumb = this.$refs.upimg.img;
					request.post('/platform/advertising/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								// this.$refs.formRef.resetFields();
								this.show = false;
							});
						}
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
.avatar-uploader > .ant-upload {
	width: 128px;
	height: 128px;
}
.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
